import { defineNuxtPlugin } from '#imports';
import { createApi } from '~/lib';
import { withTrailingSlash } from 'ufo';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const api = createApi(
    {
      baseURL: `${withTrailingSlash(config.public.apiUrl)}api/v1`,
    },
    {
      'X-Application-Identifier': config.public.appId,
      Accept: 'application/json',
    }
  );

  const affinityApi = createApi(
    {
      baseURL: `${withTrailingSlash(config.public.affinityApiUrl)}api`,
    },
    {
      'X-Application-Identifier': config.public.appId,
      Accept: 'application/json',
    }
  );

  const topsoilApi = createApi(
    {
      baseURL: `${withTrailingSlash(config.public.TOPSOIL_URL)}api`,
    },
    {
      Accept: 'application/json',
      'X-Portal-ID': 'zeronet'
    }
  );

  // @ts-ignore
  nuxtApp.$pinia.use(() => ({ $api: api, $topsoilApi: topsoilApi }));

  return {
    provide: { api, affinityApi, topsoilApi },
  };
});