import { $Fetch, $fetch, FetchOptions } from 'ofetch';
import { LooseObject } from '~/types/generic';

export class Api {
  api: $Fetch;
  headers: LooseObject;

  constructor(config: FetchOptions, headers: LooseObject) {
    this.api = $fetch.create(config);
    this.headers = headers;
  }

  private getConfig(method: string, config?: FetchOptions, body?: Record<string, any>): FetchOptions {
    if (!config) {
      return {
        method,
        headers: this.headers,
      } as FetchOptions;
    }
    let returnConfig = { method, headers: {}, ...config };
    returnConfig.headers = {
      ...returnConfig.headers,
      ...this.headers,
    };

    if (body) {
      returnConfig.body = body;
    }

    return returnConfig as FetchOptions;
  }

  public get<T>(url: string, config?: FetchOptions) {
    return this.api.raw(url, this.getConfig('GET', config));
  }

  public delete<T>(url: string, config?: FetchOptions) {
    return this.api.raw(url, this.getConfig('DELETE', config));
  }

  public head<T>(url: string, config?: FetchOptions) {
    return this.api.raw(url, this.getConfig('HEAD', config));
  }

  public post<T, B>(url: string, data?: B, config?: FetchOptions) {
    // @ts-ignore
    return this.api.raw(url, this.getConfig('POST', config, data));
  }

  public put<T, B>(url: string, data?: B, config?: FetchOptions) {
    // @ts-ignore
    return this.api.raw(url, this.getConfig('PUT', config, data));
  }

  public patch<T, B>(url: string, data?: B, config?: FetchOptions) {
    // @ts-ignore
    return this.api.raw(url, this.getConfig('PATCH', config, data));
  }
}

export function createApi(config: FetchOptions, headers: LooseObject): Api {
  return new Api(config, headers);
}
