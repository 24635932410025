import { defineNuxtPlugin, markRaw, useRouter } from '#imports';
import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  if (!config.public.gtmId) {
    return;
  }
  nuxtApp.vueApp.use(
    createGtm({
      id: config.public.gtmId,
      enabled: true,
      debug: config.public.stage !== 'production',
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
      compatibility: false,
      defer: true,
    })
  );
});
