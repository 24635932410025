<template>
  <div id="app" class="app">
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </div>
</template>

<script lang="ts" setup>
import { computed, useHead, useRoute } from '#imports';
import { generatePageMeta } from '~/lib/meta';

const route = useRoute();

const meta = computed(() =>
  generatePageMeta({
    route,
  })
);
useHead(meta);
</script>