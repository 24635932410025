import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(async () => {
  const gsap = (await import('gsap')).gsap;

  gsap.config({
    nullTargetWarn: false,
  });

  return {
    provide: { gsap },
  };
});
