import { MetaObject } from '@nuxt/schema';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { Script } from '@unhead/schema';
import { LooseObject } from '~/types/generic';

export interface PageMetaOptions {
  route: RouteLocationNormalizedLoaded;
}

export const generateScriptSnippets = ({ route }: PageMetaOptions): Script[] => {
  if (route.name == 'compass-embed') {
    return [];
  }

  return [
    {
      id: 'talkdesk-snippet',
      src: '/scripts/talkdesk.js',
      async: true,
    },
    // {
    //   id: 'ze-snippet-config',
    //   children: 'window.zESettings={webWidget:{chat:{connectOnPageLoad:false}}};',
    // },
    // {
    //   id: 'ze-snippet',
    //   src: 'https://static.zdassets.com/ekr/snippet.js?key=09f2a3f1-6d6e-4737-b26e-2afe96b59631',
    //   defer: true,
    //   async: true,
    // },
    {
      id: 'compare-snippet',
      innerHTML: `function sc(v){if (v===null){return};var dateAf=new Date();dateAf.setTime(dateAf.getTime()+(365*24*60*60*1000));document.cookie="affclick="+v+";"+"expires="+dateAf.toUTCString()+";path=/";localStorage.setItem('affclick',v)}sc((new URLSearchParams(window.location.search)).get("clickid"))`,
      // children:
      //   'function sc(v){let d=new Date();d.setTime(d.getTime()+(365*24*60*60*1000));' +
      //   'document.cookie="affclick="+v+";"+"expires="+d.toUTCString()+";path=/";' +
      //   'localStorage.setItem(\'affclick\',v)}sc((new URLSearchParams(window.location.search)).get("clickid"))',
    },
  ];
};

export function generatePageMeta({ route }: PageMetaOptions): MetaObject {
  const title = route.meta.pageTitle || '';
  const description = route.meta.pageDescription || '';

  const meta: LooseObject = {
    title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:title',
        name: 'og:title',
        content: title,
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: description,
      },
    ],
  };

  meta.script = generateScriptSnippets({ route });

  return meta as MetaObject;
}
