import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('observe', {
    mounted(el, binding) {
      new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            el.classList.add('is-observed');
            if (binding.value instanceof Function) {
              binding.value();
            }
          }
        },
        {
          rootMargin: '-70px',
        }
      ).observe(el);
    },
  });
});
