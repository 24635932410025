export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","sizes":"512x512","href":"/favicon.png"}],"style":[],"script":[{"src":"https://maps.googleapis.com/maps/api/js?key=AIzaSyBtPzv3yY4WgJOabIh_83oG7tbHf_9GEfU&libraries=places&loading=async","defer":true}],"noscript":[],"htmlAttrs":{"lang":"en"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"Unlimited Internet | Zeronet","titleTemplate":"%s | Zeronet"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null