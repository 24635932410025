import { default as _5_45month_45cart_45offerl07ot1ug4tMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45priceGSIN2Px301Meta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as abouthyrXgIB0isMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingS5dt6MvMrQMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexLsd6oyMgctMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profileKTpiOfxJybMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accounth1j6zT1X09Meta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizeDnpTlRY6qWMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promoV2Swv86eKfMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as chatUMbNAtqSYWMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embed6urxOSCLDoMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassQBgTlNxhVOMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contactcnfqcK7nbNMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/contact.vue?macro=true";
import { default as coverage6On3RZ34htMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/coverage.vue?macro=true";
import { default as glimpkV8ge5yNyXMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/glimp.vue?macro=true";
import { default as hyperfibrexbqXRuyr7MMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as index4Sr8n7hmlEMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testoWdyZ3qPXBMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as loging99EbVCKBxMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutA0OPR17hUXMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/logout.vue?macro=true";
import { default as mobileCZym43lBoaMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/mobile.vue?macro=true";
import { default as our_45plansi3jeyOYq9TMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plans20zJQsUFykMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacyz60THaJLmYMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotionsr6plZ4Xj7OMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as refer_45a_45friend4PbwMcemfSMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/refer-a-friend.vue?macro=true";
import { default as _1oXJidAdn2ZMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2VdLKnWuSSaMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3MTkbnsvex1Meta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4UudS0ISpWEMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5DoLv6XnEREMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as indexceBTf4P56wMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successvE8xZdJZXcMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerf8css7RefJMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandKmvwxzlYXYMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offeri6ulXPzWN1Meta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainabilityqGhX13QHIDMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as termsGh2Tswz5ubMeta } from "/codebuild/output/src1898308049/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: _5_45month_45cart_45offerl07ot1ug4tMeta?.name ?? "5-month-cart-offer",
    path: _5_45month_45cart_45offerl07ot1ug4tMeta?.path ?? "/5-month-cart-offer",
    meta: _5_45month_45cart_45offerl07ot1ug4tMeta || {},
    alias: _5_45month_45cart_45offerl07ot1ug4tMeta?.alias || [],
    redirect: _5_45month_45cart_45offerl07ot1ug4tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/5-month-cart-offer.vue").then(m => m.default || m)
  },
  {
    name: _6_45month_45half_45priceGSIN2Px301Meta?.name ?? "6-month-half-price",
    path: _6_45month_45half_45priceGSIN2Px301Meta?.path ?? "/6-month-half-price",
    meta: _6_45month_45half_45priceGSIN2Px301Meta || {},
    alias: _6_45month_45half_45priceGSIN2Px301Meta?.alias || [],
    redirect: _6_45month_45half_45priceGSIN2Px301Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/6-month-half-price.vue").then(m => m.default || m)
  },
  {
    name: abouthyrXgIB0isMeta?.name ?? "about",
    path: abouthyrXgIB0isMeta?.path ?? "/about",
    meta: abouthyrXgIB0isMeta || {},
    alias: abouthyrXgIB0isMeta?.alias || [],
    redirect: abouthyrXgIB0isMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accounth1j6zT1X09Meta?.path ?? "/account",
    children: [
  {
    name: billingS5dt6MvMrQMeta?.name ?? "account-billing",
    path: billingS5dt6MvMrQMeta?.path ?? "billing",
    meta: billingS5dt6MvMrQMeta || {},
    alias: billingS5dt6MvMrQMeta?.alias || [],
    redirect: billingS5dt6MvMrQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: indexLsd6oyMgctMeta?.name ?? "account",
    path: indexLsd6oyMgctMeta?.path ?? "",
    meta: indexLsd6oyMgctMeta || {},
    alias: indexLsd6oyMgctMeta?.alias || [],
    redirect: indexLsd6oyMgctMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: profileKTpiOfxJybMeta?.name ?? "account-profile",
    path: profileKTpiOfxJybMeta?.path ?? "profile",
    meta: profileKTpiOfxJybMeta || {},
    alias: profileKTpiOfxJybMeta?.alias || [],
    redirect: profileKTpiOfxJybMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/account/profile.vue").then(m => m.default || m)
  }
],
    name: accounth1j6zT1X09Meta?.name ?? undefined,
    meta: accounth1j6zT1X09Meta || {},
    alias: accounth1j6zT1X09Meta?.alias || [],
    redirect: accounth1j6zT1X09Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: authorizeDnpTlRY6qWMeta?.name ?? "authorize",
    path: authorizeDnpTlRY6qWMeta?.path ?? "/authorize",
    meta: authorizeDnpTlRY6qWMeta || {},
    alias: authorizeDnpTlRY6qWMeta?.alias || [],
    redirect: authorizeDnpTlRY6qWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: bbc_45promoV2Swv86eKfMeta?.name ?? "bbc-promo",
    path: bbc_45promoV2Swv86eKfMeta?.path ?? "/bbc-promo",
    meta: bbc_45promoV2Swv86eKfMeta || {},
    alias: bbc_45promoV2Swv86eKfMeta?.alias || [],
    redirect: bbc_45promoV2Swv86eKfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/bbc-promo.vue").then(m => m.default || m)
  },
  {
    name: chatUMbNAtqSYWMeta?.name ?? "chat",
    path: chatUMbNAtqSYWMeta?.path ?? "/chat",
    meta: chatUMbNAtqSYWMeta || {},
    alias: chatUMbNAtqSYWMeta?.alias || [],
    redirect: chatUMbNAtqSYWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: compass_45embed6urxOSCLDoMeta?.name ?? "compass-embed",
    path: compass_45embed6urxOSCLDoMeta?.path ?? "/compass-embed",
    meta: compass_45embed6urxOSCLDoMeta || {},
    alias: compass_45embed6urxOSCLDoMeta?.alias || [],
    redirect: compass_45embed6urxOSCLDoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/compass-embed.vue").then(m => m.default || m)
  },
  {
    name: compassQBgTlNxhVOMeta?.name ?? "compass",
    path: compassQBgTlNxhVOMeta?.path ?? "/compass",
    meta: compassQBgTlNxhVOMeta || {},
    alias: compassQBgTlNxhVOMeta?.alias || [],
    redirect: compassQBgTlNxhVOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/compass.vue").then(m => m.default || m)
  },
  {
    name: contactcnfqcK7nbNMeta?.name ?? "contact",
    path: contactcnfqcK7nbNMeta?.path ?? "/contact",
    meta: contactcnfqcK7nbNMeta || {},
    alias: contactcnfqcK7nbNMeta?.alias || [],
    redirect: contactcnfqcK7nbNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: coverage6On3RZ34htMeta?.name ?? "coverage",
    path: coverage6On3RZ34htMeta?.path ?? "/coverage",
    meta: coverage6On3RZ34htMeta || {},
    alias: coverage6On3RZ34htMeta?.alias || [],
    redirect: coverage6On3RZ34htMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/coverage.vue").then(m => m.default || m)
  },
  {
    name: glimpkV8ge5yNyXMeta?.name ?? "glimp",
    path: glimpkV8ge5yNyXMeta?.path ?? "/glimp",
    meta: glimpkV8ge5yNyXMeta || {},
    alias: glimpkV8ge5yNyXMeta?.alias || [],
    redirect: glimpkV8ge5yNyXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/glimp.vue").then(m => m.default || m)
  },
  {
    name: hyperfibrexbqXRuyr7MMeta?.name ?? "hyperfibre",
    path: hyperfibrexbqXRuyr7MMeta?.path ?? "/hyperfibre",
    meta: hyperfibrexbqXRuyr7MMeta || {},
    alias: hyperfibrexbqXRuyr7MMeta?.alias || [],
    redirect: hyperfibrexbqXRuyr7MMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/hyperfibre.vue").then(m => m.default || m)
  },
  {
    name: index4Sr8n7hmlEMeta?.name ?? "index",
    path: index4Sr8n7hmlEMeta?.path ?? "/",
    meta: index4Sr8n7hmlEMeta || {},
    alias: index4Sr8n7hmlEMeta?.alias || [],
    redirect: index4Sr8n7hmlEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internet_45speed_45testoWdyZ3qPXBMeta?.name ?? "internet-speed-test",
    path: internet_45speed_45testoWdyZ3qPXBMeta?.path ?? "/internet-speed-test",
    meta: internet_45speed_45testoWdyZ3qPXBMeta || {},
    alias: internet_45speed_45testoWdyZ3qPXBMeta?.alias || [],
    redirect: internet_45speed_45testoWdyZ3qPXBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/internet-speed-test.vue").then(m => m.default || m)
  },
  {
    name: loging99EbVCKBxMeta?.name ?? "login",
    path: loging99EbVCKBxMeta?.path ?? "/login",
    meta: loging99EbVCKBxMeta || {},
    alias: loging99EbVCKBxMeta?.alias || [],
    redirect: loging99EbVCKBxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutA0OPR17hUXMeta?.name ?? "logout",
    path: logoutA0OPR17hUXMeta?.path ?? "/logout",
    meta: logoutA0OPR17hUXMeta || {},
    alias: logoutA0OPR17hUXMeta?.alias || [],
    redirect: logoutA0OPR17hUXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: mobileCZym43lBoaMeta?.name ?? "mobile",
    path: mobileCZym43lBoaMeta?.path ?? "/mobile",
    meta: mobileCZym43lBoaMeta || {},
    alias: mobileCZym43lBoaMeta?.alias || [],
    redirect: mobileCZym43lBoaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: our_45plansi3jeyOYq9TMeta?.name ?? "our-plans",
    path: our_45plansi3jeyOYq9TMeta?.path ?? "/our-plans",
    meta: our_45plansi3jeyOYq9TMeta || {},
    alias: our_45plansi3jeyOYq9TMeta?.alias || [],
    redirect: our_45plansi3jeyOYq9TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/our-plans.vue").then(m => m.default || m)
  },
  {
    name: plans20zJQsUFykMeta?.name ?? "plans",
    path: plans20zJQsUFykMeta?.path ?? "/plans",
    meta: plans20zJQsUFykMeta || {},
    alias: plans20zJQsUFykMeta?.alias || [],
    redirect: plans20zJQsUFykMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacyz60THaJLmYMeta?.name ?? "privacy",
    path: privacyz60THaJLmYMeta?.path ?? "/privacy",
    meta: privacyz60THaJLmYMeta || {},
    alias: privacyz60THaJLmYMeta?.alias || [],
    redirect: privacyz60THaJLmYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promotionsr6plZ4Xj7OMeta?.name ?? "promotions",
    path: promotionsr6plZ4Xj7OMeta?.path ?? "/promotions",
    meta: promotionsr6plZ4Xj7OMeta || {},
    alias: promotionsr6plZ4Xj7OMeta?.alias || [],
    redirect: promotionsr6plZ4Xj7OMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: refer_45a_45friend4PbwMcemfSMeta?.name ?? "refer-a-friend",
    path: refer_45a_45friend4PbwMcemfSMeta?.path ?? "/refer-a-friend",
    meta: refer_45a_45friend4PbwMcemfSMeta || {},
    alias: refer_45a_45friend4PbwMcemfSMeta?.alias || [],
    redirect: refer_45a_45friend4PbwMcemfSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/refer-a-friend.vue").then(m => m.default || m)
  },
  {
    path: registerf8css7RefJMeta?.path ?? "/register",
    children: [
  {
    name: _1oXJidAdn2ZMeta?.name ?? "register-1",
    path: _1oXJidAdn2ZMeta?.path ?? "1",
    meta: _1oXJidAdn2ZMeta || {},
    alias: _1oXJidAdn2ZMeta?.alias || [],
    redirect: _1oXJidAdn2ZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register/1.vue").then(m => m.default || m)
  },
  {
    name: _2VdLKnWuSSaMeta?.name ?? "register-2",
    path: _2VdLKnWuSSaMeta?.path ?? "2",
    meta: _2VdLKnWuSSaMeta || {},
    alias: _2VdLKnWuSSaMeta?.alias || [],
    redirect: _2VdLKnWuSSaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register/2.vue").then(m => m.default || m)
  },
  {
    name: _3MTkbnsvex1Meta?.name ?? "register-3",
    path: _3MTkbnsvex1Meta?.path ?? "3",
    meta: _3MTkbnsvex1Meta || {},
    alias: _3MTkbnsvex1Meta?.alias || [],
    redirect: _3MTkbnsvex1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register/3.vue").then(m => m.default || m)
  },
  {
    name: _4UudS0ISpWEMeta?.name ?? "register-4",
    path: _4UudS0ISpWEMeta?.path ?? "4",
    meta: _4UudS0ISpWEMeta || {},
    alias: _4UudS0ISpWEMeta?.alias || [],
    redirect: _4UudS0ISpWEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register/4.vue").then(m => m.default || m)
  },
  {
    name: _5DoLv6XnEREMeta?.name ?? "register-5",
    path: _5DoLv6XnEREMeta?.path ?? "5",
    meta: _5DoLv6XnEREMeta || {},
    alias: _5DoLv6XnEREMeta?.alias || [],
    redirect: _5DoLv6XnEREMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register/5.vue").then(m => m.default || m)
  },
  {
    name: indexceBTf4P56wMeta?.name ?? "register",
    path: indexceBTf4P56wMeta?.path ?? "",
    meta: indexceBTf4P56wMeta || {},
    alias: indexceBTf4P56wMeta?.alias || [],
    redirect: indexceBTf4P56wMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: successvE8xZdJZXcMeta?.name ?? "register-success",
    path: successvE8xZdJZXcMeta?.path ?? "success",
    meta: successvE8xZdJZXcMeta || {},
    alias: successvE8xZdJZXcMeta?.alias || [],
    redirect: successvE8xZdJZXcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register/success.vue").then(m => m.default || m)
  }
],
    name: registerf8css7RefJMeta?.name ?? undefined,
    meta: registerf8css7RefJMeta || {},
    alias: registerf8css7RefJMeta?.alias || [],
    redirect: registerf8css7RefJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: simply_45broadbandKmvwxzlYXYMeta?.name ?? "simply-broadband",
    path: simply_45broadbandKmvwxzlYXYMeta?.path ?? "/simply-broadband",
    meta: simply_45broadbandKmvwxzlYXYMeta || {},
    alias: simply_45broadbandKmvwxzlYXYMeta?.alias || [],
    redirect: simply_45broadbandKmvwxzlYXYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/simply-broadband.vue").then(m => m.default || m)
  },
  {
    name: studentcard_45exclusive_45offeri6ulXPzWN1Meta?.name ?? "studentcard-exclusive-offer",
    path: studentcard_45exclusive_45offeri6ulXPzWN1Meta?.path ?? "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offeri6ulXPzWN1Meta || {},
    alias: studentcard_45exclusive_45offeri6ulXPzWN1Meta?.alias || [],
    redirect: studentcard_45exclusive_45offeri6ulXPzWN1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/studentcard-exclusive-offer.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityqGhX13QHIDMeta?.name ?? "sustainability",
    path: sustainabilityqGhX13QHIDMeta?.path ?? "/sustainability",
    meta: sustainabilityqGhX13QHIDMeta || {},
    alias: sustainabilityqGhX13QHIDMeta?.alias || [],
    redirect: sustainabilityqGhX13QHIDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: termsGh2Tswz5ubMeta?.name ?? "terms",
    path: termsGh2Tswz5ubMeta?.path ?? "/terms",
    meta: termsGh2Tswz5ubMeta || {},
    alias: termsGh2Tswz5ubMeta?.alias || [],
    redirect: termsGh2Tswz5ubMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1898308049/src/zeronet-web/pages/terms.vue").then(m => m.default || m)
  }
]