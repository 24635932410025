import { defineNuxtPlugin } from '#app/nuxt'
import { LazyElementsAppLink, LazyElementsGaugeIcon, LazyElementsHandIcons, LazyElementsHouseholdIcons, LazyElementsIconArrowRight, LazyElementsIconCheckboxMark, LazyElementsIconChevronLeft, LazyElementsIconChevronRight, LazyElementsIconClose, LazyElementsIconGoogle, LazyElementsIconHeroFeatures, LazyElementsIconHyperfibre, LazyElementsIconMoney, LazyElementsIconMoneyback, LazyElementsIconPasswordHidden, LazyElementsIconPasswordVisible, LazyElementsIconStar, LazyElementsIconSupportCall, LazyElementsIconSupportChat, LazyElementsIconSupportMail, LazyElementsIconTree, LazyElementsIconUnlimited, LazyElementsIconWorm, LazyElementsMainLogo, LazyElementsModemIcons, LazyElementsPopularPlanIcon, LazyElementsSiteIcons, LazyLayoutAppComingSoon, LazyLayoutAppFooter, LazyLayoutAppHeader, LazyLayoutAppNav, LazyLayoutAppNavAccount, LazyLayoutAppNavFooter, LazyLayoutAppPreloader, LazyLayoutAppToast, LazyProductsAddressFilled, LazyProductsAddressSearch, LazyProductsPlanCard, LazyProductsPlansPromo, LazyProductsTermToggle, LazySectionsPageHero, LazySectionsPledge, LazyUiBaseLink, LazyUiResponsiveImage, LazyUiButton, LazyUiButtonIcon, LazyUiCard, LazyUiCheckbox, LazyUiCheckboxGroup, LazyUiDatepicker, LazyUiEmbedPopup, LazyUiFormField, LazyUiInput, LazyUiLoadingOverlay, LazyUiNotification, LazyUiNotificationPopup, LazyUiRadio, LazyUiSwitch, LazyUiTextarea, LazyUiToggle, LazyUiUiAccordion, LazyUiUiAccordionHeader, LazyUiUiAccordionPanel } from '#components'
const lazyGlobalComponents = [
  ["ElementsAppLink", LazyElementsAppLink],
["ElementsGaugeIcon", LazyElementsGaugeIcon],
["ElementsHandIcons", LazyElementsHandIcons],
["ElementsHouseholdIcons", LazyElementsHouseholdIcons],
["ElementsIconArrowRight", LazyElementsIconArrowRight],
["ElementsIconCheckboxMark", LazyElementsIconCheckboxMark],
["ElementsIconChevronLeft", LazyElementsIconChevronLeft],
["ElementsIconChevronRight", LazyElementsIconChevronRight],
["ElementsIconClose", LazyElementsIconClose],
["ElementsIconGoogle", LazyElementsIconGoogle],
["ElementsIconHeroFeatures", LazyElementsIconHeroFeatures],
["ElementsIconHyperfibre", LazyElementsIconHyperfibre],
["ElementsIconMoney", LazyElementsIconMoney],
["ElementsIconMoneyback", LazyElementsIconMoneyback],
["ElementsIconPasswordHidden", LazyElementsIconPasswordHidden],
["ElementsIconPasswordVisible", LazyElementsIconPasswordVisible],
["ElementsIconStar", LazyElementsIconStar],
["ElementsIconSupportCall", LazyElementsIconSupportCall],
["ElementsIconSupportChat", LazyElementsIconSupportChat],
["ElementsIconSupportMail", LazyElementsIconSupportMail],
["ElementsIconTree", LazyElementsIconTree],
["ElementsIconUnlimited", LazyElementsIconUnlimited],
["ElementsIconWorm", LazyElementsIconWorm],
["ElementsMainLogo", LazyElementsMainLogo],
["ElementsModemIcons", LazyElementsModemIcons],
["ElementsPopularPlanIcon", LazyElementsPopularPlanIcon],
["ElementsSiteIcons", LazyElementsSiteIcons],
["LayoutAppComingSoon", LazyLayoutAppComingSoon],
["LayoutAppFooter", LazyLayoutAppFooter],
["LayoutAppHeader", LazyLayoutAppHeader],
["LayoutAppNav", LazyLayoutAppNav],
["LayoutAppNavAccount", LazyLayoutAppNavAccount],
["LayoutAppNavFooter", LazyLayoutAppNavFooter],
["LayoutAppPreloader", LazyLayoutAppPreloader],
["LayoutAppToast", LazyLayoutAppToast],
["ProductsAddressFilled", LazyProductsAddressFilled],
["ProductsAddressSearch", LazyProductsAddressSearch],
["ProductsPlanCard", LazyProductsPlanCard],
["ProductsPlansPromo", LazyProductsPlansPromo],
["ProductsTermToggle", LazyProductsTermToggle],
["SectionsPageHero", LazySectionsPageHero],
["SectionsPledge", LazySectionsPledge],
["UiBaseLink", LazyUiBaseLink],
["UiResponsiveImage", LazyUiResponsiveImage],
["UiButton", LazyUiButton],
["UiButtonIcon", LazyUiButtonIcon],
["UiCard", LazyUiCard],
["UiCheckbox", LazyUiCheckbox],
["UiCheckboxGroup", LazyUiCheckboxGroup],
["UiDatepicker", LazyUiDatepicker],
["UiEmbedPopup", LazyUiEmbedPopup],
["UiFormField", LazyUiFormField],
["UiInput", LazyUiInput],
["UiLoadingOverlay", LazyUiLoadingOverlay],
["UiNotification", LazyUiNotification],
["UiNotificationPopup", LazyUiNotificationPopup],
["UiRadio", LazyUiRadio],
["UiSwitch", LazyUiSwitch],
["UiTextarea", LazyUiTextarea],
["UiToggle", LazyUiToggle],
["UiUiAccordion", LazyUiUiAccordion],
["UiUiAccordionHeader", LazyUiUiAccordionHeader],
["UiUiAccordionPanel", LazyUiUiAccordionPanel]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
