import { defineNuxtPlugin, markRaw } from '#imports';
import { createPersistedState } from 'pinia-plugin-persistedstate';

export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore
  nuxtApp.$pinia.use(({ store }) => {
    store.$auth0 = markRaw(nuxtApp.vueApp.config.globalProperties['$auth0']);
  });

  // @ts-ignore
  nuxtApp.$pinia.use(
    createPersistedState({
      storage: sessionStorage,
    })
  );
});
